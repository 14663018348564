<template>
	<!-- 测试-用例详情 -->
	<GModule class="container">
		<GTitle name="用例详情" style="margin: 20px;"></GTitle>
		<div class="detail-all">
			<a-form
				class="need-box"
			    :model="formState"
			    name="basic"
			    :label-col="{ span: 8}"
			    :wrapper-col="{ span: 11}"
			    autocomplete="off"
				:scrollToFirstError='true'>
				<a-form-item  v-if="dev_complete == 1" style="position: absolute;left: 30px;top: 80px;">
					<div style="width: 180px;display: flex;justify-content: space-between;">
						<a-button
							:class="info.dev_status == 'pass' || info.status=='pass'||info.status=='reviewing'||info.status=='wait' ? 'btn clo6' : 'btn clo1'" 
							@click="completeClick()">完成测试</a-button>
						<a-button class="btn clo6" @click="goBack()">返回</a-button>
					</div>
				</a-form-item>	
				<a-form-item label="用例ID">
				    <a-input :value="info.id" disabled/>
				</a-form-item>
				<a-form-item label="用例类型">
					 <a-input :value="info.case_type_text" disabled/>
				</a-form-item>
				<a-form-item label="用例摘要">
				    <a-textarea style="height: 150px;" :value="info.describe" disabled/>
				</a-form-item>
				<a-form-item label="前置条件">
				    <a-textarea style="height: 150px;" :value="info.preconditions" disabled/>
				</a-form-item>
				<a-form-item label="操作步骤">
				    <p v-for="(item,index) in info.steps" :key="index">
						{{item.index}}.{{item.content}}
					</p>
				</a-form-item>
				<a-form-item label="预期结果">
				    <a-textarea style="height: 150px;" :value="info.results" disabled/>
				</a-form-item>
				<a-form-item label="需求模块">
				    <a-breadcrumb separator=">" style="width: 400px;margin-bottom: 14px;">
				        <a-breadcrumb-item 
							v-for="(item,index) in modularsArr" 
							:key="index">
							{{item}}
						</a-breadcrumb-item>
				     </a-breadcrumb>
				</a-form-item>
				<a-form-item label="需求描述">
				    <a-textarea style="height: 150px;" :value="info2.describe" disabled/>
				</a-form-item>
				<a-form-item label="参数/逻辑说明">
				    <a-textarea style="height: 150px;" :value="info2.explain" disabled/>
				</a-form-item>
				<a-form-item label="需求图片">
				    <div class="img-scroll" @click="openSwiper()">
				    	<a-image :preview="false" :width="90" :src="itm" v-for="(itm,ind) in info2.imgArr" :key="ind"/>
				    </div>
				</a-form-item>
				<a-form-item  :wrapper-col="{ offset:8 }">
					<a-button class="btn clo6" @click="goBack()" v-if="dev_complete == 0">返回</a-button>
					<template v-if="submit == 1">
						<a-popconfirm
							v-if="status == 'wait' || status == 'fail'"
							title="确定提审吗?"
							ok-text="确定"
							cancel-text="取消"
							@confirm="confirm()">
							<a-button class="btn clo1">提审</a-button>
						</a-popconfirm>
					</template>
					<a-button v-if="edit == 1" class="btn clo3"  @click="updateClick(info.id)">修改</a-button>
					<a-popconfirm
						v-if="del == 1"
						title="确认是否删除?"
						ok-text="确定"
						cancel-text="取消"
						@confirm="del(info.id)">
						<a-button class="btn" type="danger">删除</a-button>
					</a-popconfirm>
					<a-button  
						v-if="bug == 1" 
						class="btn clo2" 
						@click="status!='wait_test'?false : goToBug()">转BUG</a-button>
					<a-popconfirm
						v-if="review == 1"
						title="确定通过测试用例?"
						ok-text="确定"
						cancel-text="取消"
						:disabled="status =='pass'"
						@confirm="reviewBtn()">
						<a-button :class="status == 'pass'?'clo6':'clo3'">通过</a-button>
					</a-popconfirm>	
					&nbsp;&nbsp;
					<a-button v-if="fail == 1" :class="status!= 'wait' && status!='part'?'clo6':'clo4'" @click="setFailModal()">驳回</a-button>
				</a-form-item>
			</a-form>
			<a-modal v-if="failModal" v-model:visible="failModal" title="驳回理由" @ok="failSubmit()">
				<a-form-item label="驳回理由">
					<a-input v-model:value="failText" placeholder="请输入驳回理由" />
				</a-form-item>
			</a-modal>
		</div>
	</GModule>	
</template>

<script>
	export default{
		name:'TheTestCaseDetail',
		data(){
			return{
				id:0 ,// 详情id
				info:{} ,// 详情
				modularsArr:[] ,//需求模块
				info2:{} ,// 需求信息
				status:'',
				del:0,
				edit:0,
				submit:0,
				bug:0,
				review:0,
				fail:0,
				failText:'',
				failModal:false,
				dev_complete:0
			}
		},
		async created() {
			this.$store.commit('setStore', { name: 'hideSider', data: 1 })
			this.id = this.$route.query.id || 0
			this.status = this.$route.query.status || ''
			this.del = this.$route.query.del
			this.edit = this.$route.query.edit
			this.submit = this.$route.query.submit
			this.bug = this.$route.query.bug
			this.review  = this.$route.query.review
			this.fail  = this.$route.query.fail
			this.dev_complete = this.$route.query.dev_complete
			this.getDetail()
		},
		methods:{
			async getDetail(){
				if(this.id){
					let res = await this.$utils.api.post({
						url:'/cases/getRow',
						data:{
							ids:this.id
						},
						result:1
					})
					if(res.code == 1){
						this.modularsArr = res.data.demand.modulars
						this.info = res.data
						res.data.demand.imgArr = res.data.demand.images.length > 0 ? res.data.demand.images.split(',') : []
						this.info2 = res.data.demand
						this.$store.commit('setStore',{ name:'history', data:{list:res.data.history,type:'cases',id:res.data.id} })
					}
				}
			},
			openSwiper(){
				// 弹出图片列表
				this.$store.commit('setSwiperArr',this.info.demand.imgArr)
				this.$store.commit('setSwiper',1)
			},
			updateClick(id){
				  // 修改
				  this.$router.push({name:'TheTestCaseAdd',query:{id:id}})
			},
			async del(id){
				// 删除
				let res = await this.$utils.api.post({
					url:'/cases/del',
					data:{
						ids:id
					},
					  result:1
				})
				if(res.code == 1){
					this.$utils.msgSuc('删除成功')
					this.goBack()
				}
			},
			goBack(){
				// 返回
				this.$store.commit('setStore', { name: 'hideSider', data: null })
				this.$router.back()
			},
			async goToBug(){
				// 转bug
				this.$router.push({name:'SubmitBug',query:{id:this.info2.id}})
			},
			async confirm(){
				// 确认提审
				let res = await this.$utils.api.post({
					url:'/cases/submit',
					data:{
						ids:this.id
					},
					result:1
				})
				if(res.code == 1){
					this.$utils.msgSuc('提审成功')
				}
			},
			async reviewBtn(){
				// 通过
				let res = await this.$utils.api.post({
					url:'/cases/review',
					data:{
						ids:this.id
					},
					result:1
				})
				if(res.code == 1){
					this.$utils.msgSuc('通过成功')
					this.status = 'pass'
				}
			},
			setFailModal(){
				if(status =='wait'||status=='part'){
			  		this.failModal = true;
				}
			},
			async failSubmit(){
				if(!this.failText){
					return this.$utils.msgErr('请输入驳回理由！')
				}
				let res = await this.$utils.api.post({
					url:"/cases/fail",
					data:{
						ids: this.id,
						msg: this.failText
					},
					result:1
				})
				if(res.code==1){
					this.$utils.msgSuc('已驳回')
					this.failModal = false;
					this.failText = '';
					this.status = 'fail'
				}
			},
			async completeClick(){
				// 完成测试
				if(this.info.dev_status == 'pass' || this.info.status=='pass'||this.info.status=='reviewing'||this.info.status=='wait'){
					return
				}
				
				let res = await this.$utils.api.post({
					url:'/cases/dev_complete',
					data:{
						ids:this.id
					},
					result:1
				})
				if(res.code == 1){
					this.$utils.msgSuc('完成测试成功')
					this.getDetail()
				}
			}
		}	
	}
</script>

<style lang="scss" scoped>
.container{
	margin: 20px;
	padding: 0 0 50px 0;
	.detail-all{
		padding: 30px 0;
		max-height: 92%;
		overflow-y: auto;
		overflow-x: hidden;
		&::-webkit-scrollbar {
			width: 0px; // 控制滚动条
		}
		&::-webkit-scrollbar-thumb{
			background-color: #bfbfbf; // 滚动条颜色
		}
		&:hover{
			&::-webkit-scrollbar {
				width: 1px;
			}
		}
		.need-box{
			.btn{
				margin-right: 10px;
			}
			.img-scroll{
				height: 90px;
				overflow-x: auto;
				overflow-y: hidden;
				display: flex;
				align-items: center;
				&::-webkit-scrollbar {
					height: 0px; // 控制滚动条
				}
				&::-webkit-scrollbar-thumb{
					background-color: #a5ddff; // 滚动条颜色
				}
				&:hover{
					&::-webkit-scrollbar {
						height: 8px;

					}
				}
			}
			.overimg-box2{
				.overimg{
					display: flex;
					height: 90px;
					overflow: hidden;
				}
			}
		}
	}
}
</style>